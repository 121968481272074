import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

interface IStatus {
  status: string;
}

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .box {
    background: #fbfbfb;
    border-radius: 10px;

    p.h2 {
      color: #202020;
    }

    p > span {
      color: #202020;
    }

    .btn-pay {
      background: #202020;
      color: #fff;

      :hover {
        background: ${lighten(0.1, '#202020')};
      }
    }
  }

  .my-games {
    border-bottom: 4px solid #efefef;

    > div {
      margin-bottom: -20px;

      button {
        transition-duration: 0.3s;
        color: #a4a4a4;
        border-bottom: 4px solid transparent !important;
      }

      button:hover,
      button.active {
        color: #100f0f;
        border-color: #0d0d0d !important;
      }
    }

    .btn-new {
      border-color: #00ab64 !important;
      background-color: #00ab64 !important;
      color: #ffffff !important;

      :hover {
        border-color: ${darken(0.05, '#00AB64')} !important;
        background-color: ${darken(0.05, '#00AB64')} !important;
      }
    }
  }

  .table-content {
    > div {
      padding: 0;

      > div:nth-child(1) {
        padding: 0;
        border: none;

        .content {
          padding-bottom: 0;

          .tb-header {
            display: none !important;
          }

          .tb-header,
          .tb-body {
            width: 100%;

            .tb-row {
              width: 100%;
              padding: 0 20px;

              div:nth-child(2) {
                width: 100px;
              }

              div:nth-child(4) {
                width: 250px;
              }

              div:nth-child(5) {
                width: 50px;
              }

              :nth-child(odd) {
                background: #fbfbfb;
                border-radius: 8px;
              }

              :nth-child(even) {
                background: transparent;
                border-radius: 8px;
              }
            }
          }
        }

        :before,
        :after {
          display: none !important;
        }
      }
      > div:nth-child(2) {
        margin-top: 10px !important;
      }
    }
  }
`;

export const Status = styled.div<IStatus>`
  p {
    width: calc(100% - 25px);
  }

  ${(props) =>
    props.status === 'paid' &&
    css`
      background: #defff1;
      p {
        color: #00ab64;
      }
    `}

  ${(props) =>
    props.status === 'Awaiting Payment' &&
    css`
      background: #fff1dd;
      p {
        color: #927221;
      }
    `}

  ${(props) =>
    props.status === 'Overdue' &&
    css`
      background: #ffdfde;
      p {
        color: #e14640;
      }
    `}
`;
