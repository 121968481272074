import { darken, lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .banner {
    > .slick-slider {
      padding-bottom: 10px;

      > .slick-list {
        padding-bottom: 10px;

        > .slick-track {
          padding-bottom: 10px;

          > .slick-slide {
            padding-bottom: 10px;

            > div {
              padding-bottom: 10px;

              img {
                border-radius: 10px;
                height: 338px;
              }
            }
          }
        }
      }

      .slick-dots {
        bottom: 0;

        li button::before {
          width: 10px;
          height: 10px;
          background: #d9d9d9;
          color: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          opacity: 1;
        }

        li.slick-active button::before {
          background: #00ab64;
        }

        li.slick-active button:hover {
          ::before {
            background: #00ab64;
          }
        }
      }
    }
  }

  .news {
    img {
      mix-blend-mode: normal;
      border-radius: 16px;
    }

    p {
      color: #202020;
    }
  }

  .models {
    .box-model {
      background: #ffffff;
      border: 1px solid #d7cfcf;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
      border-radius: 17px;

      img {
        border-radius: 17px 17px 0px 0px;
      }

      p span {
        color: #202020;
      }
    }
  }

  .options {
    border-bottom: 4px solid #efefef;

    > div {
      margin-bottom: -20px;

      a {
        display: inline-block;
        transition-duration: 0.3s;
        color: #a4a4a4;
        border-bottom: 4px solid transparent !important;
      }

      a:hover,
      a.active {
        color: #100f0f;
        border-color: #0d0d0d !important;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 1.5rem;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    img {
      border-radius: 10px;
    }

    h3.h2 {
      color: #202020;
    }
  }
`;

export const NoData = styled.div`
  p {
    color: #a4a4a4 !important;
  }
`;
