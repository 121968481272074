import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';
import SignUp from '~/pages/SignUp';

import Home from '~/pages/Home';
import MyPerformance from '~/pages/MyPerformance';
import Subscriptions from '~/pages/Subscriptions';
import Plans from '~/pages/Plans';
import News from '~/pages/News';
import Profile from '~/pages/Profile';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resetar-senha/:code`}
        exact
        component={ResetPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cadastro`}
        exact
        component={SignUp}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/home`}
        exact
        isPrivate
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/minha-performance`}
        exact
        isPrivate
        component={MyPerformance}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas`}
        exact
        isPrivate
        component={Subscriptions}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/assinaturas/planos`}
        exact
        isPrivate
        component={Plans}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/novidades`}
        exact
        isPrivate
        component={News}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/novidades/modelos`}
        exact
        isPrivate
        component={News}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/perfil`}
        exact
        isPrivate
        component={Profile}
      />
    </Switch>
  );
};

export default routes;
