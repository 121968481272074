import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .box {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 3px 4px 23px rgba(95, 95, 95, 0.1),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(230, 230, 230, 0.5);
    border-radius: 18px;

    .plan-name {
      background: #aef7ac;
      color: #2d2e2e;
    }

    /* .description {
      min-height: 136px;
    } */

    .duration {
      color: #000;
    }

    button {
      background: #f3f3f3;
      border-radius: 10px;
      color: #202020;
    }
  }

  .box.recommended {
    background: #303030;
    color: #a6a6a6;

    .alert-recommended {
      background: #fff961;
      color: #2d2e2e;
    }

    .plan-name {
      background: #72bd6f;
    }

    .duration {
      color: #fff;
    }

    .month {
      color: #a6a6a6;
    }

    .description {
      color: #e4e4e4;
    }

    button {
      background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
      border-radius: 18px;
    }
  }
`;
