import { darken, lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import bgModelBox from '~/assets/defaults/bg-model-box.png';
import mobileBgModelBox from '~/assets/defaults/mobile-bg-model-box.png';

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .model-box {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    > div {
      position: relative;
      z-index: 2;

      .brand {
        margin-left: 1rem !important;
      }
    }

    :after {
      content: '';
      background-image: url(${mobileBgModelBox});
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      width: 218px;
      height: 200px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  .box {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;

    h6 {
      color: #202020;
    }

    p {
      color: #202020;
    }

    .sub-box {
      background: #f9f9f9;
      border-radius: 6px;

      .cards {
        width: 20px;
        height: 20px;

        div {
          width: 11px;
          height: 16px;
          border-radius: 1px;
          position: relative;

          :before {
            content: '';
            width: 11px;
            height: 16px;
            border-radius: 1px;
            background-color: #2d2e2e;
            position: absolute;
            top: -2px;
            right: -4px;
            display: block;
          }

          :after {
            content: '';
            width: 11px;
            height: 16px;
            border-radius: 1px;
            background-color: #202020;
            position: absolute;
            top: 0px;
            right: 0px;
            display: block;
          }
        }
      }

      .cards.yellow div {
        :before {
          background-color: #ffca18;
        }

        :after {
          background-color: #e5b100;
        }
      }

      .cards.red div {
        :before {
          background-color: #e14640;
        }

        :after {
          background-color: #ce130c;
        }
      }

      + .sub-box {
        margin-left: 5px;
      }
    }
  }

  .my-games {
    border-bottom: 4px solid #efefef;

    > div {
      margin-bottom: -20px;

      button {
        transition-duration: 0.3s;
        color: #a4a4a4;
        border-bottom: 4px solid transparent !important;
      }

      button:hover,
      button.active {
        color: #100f0f;
        border-color: #0d0d0d !important;
      }
    }

    .btn-new {
      border-color: #00ab64 !important;
      background-color: #00ab64 !important;
      color: #ffffff !important;

      :hover {
        border-color: ${darken(0.05, '#00AB64')} !important;
        background-color: ${darken(0.05, '#00AB64')} !important;
      }
    }
  }

  .table-content {
    > div {
      padding: 0;

      > div:nth-child(1) {
        padding: 0;
        border: none;

        .content {
          padding-bottom: 0;

          .tb-header {
            display: none !important;
          }

          .tb-header,
          .tb-body {
            width: 100%;

            .tb-row {
              width: 100%;
              padding: 0 20px;
              position: relative;

              > div {
                > div {
                  margin-bottom: 10px;
                }

                :nth-child(5) {
                  position: absolute;
                  width: 24px;
                  height: 24px;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  right: 20px;
                  top: 20px;
                }
              }

              :nth-child(odd) {
                background: #fbfbfb;
                border-radius: 8px;
              }

              :nth-child(even) {
                background: transparent;
                border-radius: 8px;
              }
            }
          }
        }

        :before,
        :after {
          display: none !important;
        }
      }
      > div:nth-child(2) {
        margin-top: 10px !important;
      }
    }
  }

  @media screen and (min-width: 380px) {
    .model-box {
      > div {
        .brand {
          margin-left: 3rem !important;
        }
      }
    }
  }

  @media screen and (min-width: 991px) {
    .model-box {
      :after {
        content: '';
        background-image: url(${mobileBgModelBox});
        background-position: right;
        width: 305px;
        height: 264px;
      }
    }

    .table-content {
      > div {
        > div:nth-child(1) {
          .content {
            .tb-header,
            .tb-body {
              .tb-row {
                > div:nth-child(2) {
                  width: 100px;
                  div {
                    margin-bottom: 0;
                  }
                }

                > div:nth-child(4) {
                  width: 250px;
                }

                > div:nth-child(5) {
                  position: unset;
                  width: 50px;
                  height: unset;
                  padding: 15px;
                  display: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .model-box {
      :after {
        content: '';
        background-image: url(${bgModelBox});
      }
    }
  }
`;

export const Score = styled.div`
  background: rgba(66, 169, 76, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;

  p {
    color: #42a959;
    font-weight: 600;
    margin: 0;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 1.5rem;

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    img {
      border-radius: 10px;
    }

    h3.h2 {
      color: #202020;
    }

    input {
      color: #18191a;

      ::placeholder {
        color: ${lighten(0.1, '#8692a6')};
      }
    }

    button.btn-save {
      background: #005c37;
      width: 150px;
      border-radius: 10px;
    }
  }
`;
