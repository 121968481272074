import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import FloatProfile from '~/components/FloatProfile';
import BoxPlan from '~/components/BoxPlan';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

interface IPlanResponse {
  id: string;
  name: string;
  description: string;
  price: number;
  time: number;
  pairs: number;
  recommended: boolean;
}

interface IPlan {
  id: string;
  name: string;
  description: string;
  price: string;
  totalPrice: string;
  time: string;
  pairs: string;
  recommended: boolean;
}

const Plans: React.FC = () => {
  const [plans, setPlans] = useState<IPlan[]>([]);

  useEffect(() => {
    api.get<IPlanResponse[]>('plans').then((response) => {
      const data = response.data.map<IPlan>((plan) => {
        const timeChange = plan.time / plan.pairs;
        const pairs = plan.pairs.toString().padStart(2, '0');
        return {
          ...plan,
          price: formatPrice(plan.price),
          totalPrice: formatPrice(
            plan.time * plan.price - plan.time * plan.price * 0.16666666666666
          ),
          pairs: `${pairs} ${
            plan.pairs > 1 ? 'Pares de chuteiras' : 'Par de chuteira'
          }, com troca a cada ${timeChange} meses.`,
          time: plan.time.toString().padStart(2, '0'),
        };
      });

      setPlans(data);
    });
  }, []);

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 mt-4 mt-lg-0">
            <h2 className="fw-semibold">Planos</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
          <div className="col-12 pe-lg-5 mt-3 mb-4">
            <BoxPlan className="p-4" />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 pe-lg-5">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="mb-4 h5 fw-semibold">Outros planos</h2>
            </div>
            <div className="row align-items-center">
              {plans.map((plan) => (
                <div
                  key={plan.id}
                  className="col-lg-12 col-xl-4 mb-lg-4 mb-xl-0"
                >
                  <div
                    className={`box px-4 ${
                      plan.recommended ? 'py-4 recommended' : 'py-3'
                    }`}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <p className="plan-name mb-0 rounded-pill px-3 py-1 fw-semibold">
                        {plan.name}
                      </p>
                      {plan.recommended && (
                        <p className="alert-recommended rounded-pill px-3 py-1 mb-0 fw-semibold">
                          Recomendado
                        </p>
                      )}
                    </div>
                    <div className=" lign-items-center mb-3">
                      <div>
                        <p className="duration h3 fw-semibold mb-0">
                          <span className="big">
                            {plan.time} x{' '}
                            <span className="small fw-semibold">
                              <span className="small fw-semibold">R$</span>
                            </span>{' '}
                            {plan.price}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="h6 mb-0 fw-normal">
                          <span className="small">ou R$</span>{' '}
                          <span className="big fw-semibold">
                            {plan.totalPrice}
                          </span>
                          <span className="small">/período</span>
                        </p>
                      </div>
                    </div>
                    <p className="description">
                      <b className="d-block">{plan.description}</b>
                    </p>
                    <button
                      type="button"
                      className="border-0 fw-medium mt-3 h5 w-100 py-3"
                    >
                      Saiba mais
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Plans;
