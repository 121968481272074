import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { RiArrowLeftSLine, RiCheckLine } from 'react-icons/ri';

import getValidationsErrors from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import { Container, Background } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';

interface IFormData {
  first_name: string;
  last_name: string;
  birthdate: string;
  celphone: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const { signIn } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [errorAcceptTerms, setErrorAcceptTerms] = useState('');

  const handleChangeTerms = useCallback((e) => {
    setAcceptTerms(e.target.checked);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setErrorAcceptTerms('');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          first_name: Yup.string().required('O nome é obrigatório'),
          last_name: Yup.string().required('O sobrenome é obrigatório'),
          birthdate: Yup.string().required('O nascimento é obrigatório'),
          celphone: Yup.string().required('O celular é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
          terms: Yup.string().when('$terms', {
            is: (termsCheck: boolean) => termsCheck,
            then: Yup.string().required('O aceite dos termos é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            terms: !acceptTerms,
          },
        });

        const { first_name, last_name, birthdate, celphone, email, password } =
          data;

        const formData = {
          name: `${first_name} ${last_name}`,
          birthdate,
          celphone,
          email,
          password,
        };

        console.log(formData);

        // await api.post('users/sessions/forgot-password', formData);

        signIn({ email, password });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          formRef.current?.setErrors(errors);
          if (errors.terms) {
            setErrorAcceptTerms('O aceite dos termos é obrigatório');
          }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao te cadastrar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [acceptTerms, signIn]
  );

  return (
    <Container className="container-fluid d-flex flex-column justify-content-center py-4 py-lg-0">
      <div className="row h-100">
        <Background className="col-lg-4 d-none d-lg-block" />
        <div className="col-12 h-100">
          <div className="container h-100">
            <div className="row h-100 justify-content-end">
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="col-lg-8 align-self-center"
              >
                <div className="row">
                  <div className="col-12 d-flex flex-wrap justify-content-between mb-0 mb-lg-5 pb-5">
                    <div className="w-100">
                      <Link to="/" className="d-block mb-5">
                        <RiArrowLeftSLine size={44} color="#B8B9BA" />
                      </Link>
                    </div>
                    <div>
                      <h1 className="fw-semibold">
                        Seja bem-vindo(a)!{' '}
                        <span className="d-none d-lg-inline-block">⚽</span>
                      </h1>
                    </div>
                    <div className="ms-auto mt-4 mt-lg-0">
                      <p className="mb-0 text-end">Indicado por:</p>
                      <p className="h5 fw-normal text-end text-primary">
                        @Namebyreferral
                      </p>
                    </div>
                  </div>
                  <label className="col-lg-6 mb-3 mb-lg-4">
                    <span className="d-block mb-2">Nome</span>
                    <Input
                      name="first_name"
                      placeholder="Ex: João"
                      className="input"
                    />
                  </label>
                  <label className="col-lg-6 mb-3 mb-lg-4">
                    <span className="d-block mb-2">Sobrenome</span>
                    <Input
                      name="last_name"
                      placeholder="Ex: Silva"
                      className="input"
                    />
                  </label>
                  <label className="col-lg-6 mb-3 mb-lg-4">
                    <span className="d-block mb-2">Nascimento</span>
                    <InputMask
                      kind="custom"
                      options={{
                        mask: '99/99/9999',
                      }}
                      name="birthdate"
                      placeholder="00/00/0000"
                      className="input"
                    />
                  </label>
                  <label className="col-lg-6 mb-3 mb-lg-4">
                    <span className="d-block mb-2">Celular</span>
                    <InputMask
                      kind="cel-phone"
                      name="celphone"
                      placeholder="(00) 00000-0000"
                      className="input"
                    />
                  </label>
                  <label className="col-lg-6 mb-3 mb-lg-5">
                    <span className="d-block mb-2">E-mail</span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="email@exemplo.com.br"
                      className="input"
                    />
                  </label>
                  <label className="col-lg-6 mb-5">
                    <span className="d-block mb-2">Senha</span>
                    <Input
                      type="password"
                      name="password"
                      placeholder="********"
                      className="input"
                    />
                  </label>
                  <label className="col-lg-10 mb-5">
                    <div className="d-flex">
                      <div
                        className={`checkbox me-2 ${
                          acceptTerms ? 'checked' : ''
                        }`}
                      >
                        <RiCheckLine size={18} color="#fff" />
                      </div>
                      <input
                        type="checkbox"
                        name="terms"
                        className="d-none"
                        onChange={handleChangeTerms}
                      />
                      <span className="d-block mb-2 terms">
                        Eu concordo que Knarin armazene e processe meus dados
                        pessoais conforme descrito nos{' '}
                        <Link to="/" className="text-primary fw-bold">
                          Termos de Serviço
                        </Link>
                        .
                      </span>
                    </div>
                    {errorAcceptTerms && (
                      <small className="text-error">{errorAcceptTerms}</small>
                    )}
                  </label>
                  <div className="col-lg-11 ms-auto">
                    <button
                      type="submit"
                      className="btn btn-submit d-block mt-5 fw-semibold mb-3 w-100 w-lg-50 ms-auto"
                    >
                      Cadastrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignUp;
