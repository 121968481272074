import { differenceInDays, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FiAlertCircle, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { Link, useLocation } from 'react-router-dom';

import StarFullOutline from '~/assets/icons/StarFullOutline.svg';
import { useAuth } from '~/hooks/Auth';

import { Container, Status } from './styles';

interface IBoxPlan {
  className?: string;
}

const BoxPlan: React.FC<IBoxPlan> = ({ className }) => {
  const location = useLocation();
  const { user } = useAuth();
  const [showButtonUpgrade, setShowButtonUpgrade] = useState(true);
  const [info, setInfo] = useState('');

  useEffect(() => {
    setShowButtonUpgrade(!location.pathname.includes('/planos'));
  }, [location.pathname]);

  useEffect(() => {
    if (user.subscription) {
      const currentDate = new Date();
      const dueDate =
        user.subscription.orders.length > 0
          ? parseISO(user.subscription.orders[0].due_date)
          : new Date();
      const days = differenceInDays(currentDate, dueDate);
      let infoData = `Sua assinatura irá vencer em ${days} ${
        days > 1 ? 'dias' : 'dia'
      }`;
      if (
        user.subscription.orders.length > 0 &&
        user.subscription.orders[0].status !== 'paid' &&
        dueDate < currentDate
      ) {
        infoData = `Seu pagamento está em atraso`;
      }

      setInfo(infoData);
    }
  }, [user.subscription]);

  return (
    <>
      {user.subscription && (
        <Container className={`box-plan ${className}`}>
          <div className="d-flex align-items-center flex-wrap">
            <div>
              <div className="d-flex align-items-center infos">
                <img src={StarFullOutline} alt="star full outline" />
                <p className="ms-2 ms-lg-4 mb-0 me-lg-2 text-plan">
                  Meu Plano de Assinatura
                </p>
                <span className="px-3 py-1 fw-semibold plan-name">
                  {user.subscription.plan.name}
                </span>
              </div>
              {info &&
                user.subscription.orders.length > 0 &&
                user.subscription.orders[0].status !== 'paid' && (
                  <Status
                    status={user.subscription.orders[0].status}
                    className="sub-box d-flex align-items-center px-3 py-1 rounded-pill warning order-0 order-lg-1 mb-3 mb-lg-0 mt-3"
                  >
                    {user.subscription.orders[0].status === 'paid' && (
                      <FiCheckCircle
                        size={16}
                        color="#00ab64"
                        className="me-3"
                      />
                    )}
                    {user.subscription.orders[0].status ===
                      'Awaiting Payment' && (
                      <FiAlertCircle
                        size={16}
                        color="#927221"
                        className="me-3"
                      />
                    )}
                    {user.subscription.orders[0].status === 'Overdue' && (
                      <FiXCircle size={16} color="#e14640" className="me-3" />
                    )}
                    <p className="mb-0">{info}</p>
                  </Status>
                )}
            </div>
            {showButtonUpgrade && (
              <Link
                to={`${process.env.PUBLIC_URL}/assinaturas/planos`}
                className="ms-auto"
              >
                <span className="fw-medium me-1">Upgrade</span>
                <VscArrowSmallRight size={24} color="#202020" />
              </Link>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default BoxPlan;
