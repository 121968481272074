import React, { useCallback, useEffect, useState } from 'react';
import {
  RiFootballFill,
  RiHome4Line,
  RiLogoutCircleRLine,
  RiNewspaperLine,
  RiUser3Line,
} from 'react-icons/ri';
import { TiStarOutline } from 'react-icons/ti';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

import { Container } from './styles';
import FloartProfile from '../FloatProfile';
import BoxPlan from '../BoxPlan';

import mobileLogo from '~/assets/logos/mobile-logo.svg';

const Menu: React.FC = () => {
  const location = useLocation();
  const { signOut } = useAuth();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(false);
  }, [location]);

  const handleClickMenu = useCallback(() => {
    setOpened((state) => !state);
  }, []);

  return (
    <Container className="menu">
      <div className="fixed-top ps-lg-5">
        <div className="d-flex d-lg-none align-items-center justify-content-between px-4 py-3">
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <img src={mobileLogo} alt="mobile logo" />
          </Link>
          <button
            type="button"
            className={`border-0 bg-transparent ${opened ? 'opened' : ''}`}
            onClick={handleClickMenu}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div
          className={`d-flex flex-column align-items-start justify-content-lg-center pt-5 pt-lg-0 px-4 px-lg-0 ${
            opened ? 'opened' : ''
          }`}
        >
          <small className="pb-2 d-none d-lg-block">MENU</small>
          <div className="box p-4 d-block d-lg-none w-100 mb-5">
            <FloartProfile />
            <hr />
            <BoxPlan className="p-0" />
          </div>
          <NavLink
            to={`${process.env.PUBLIC_URL}/home`}
            className="mt-4"
            activeClassName="active"
          >
            <RiHome4Line size={24} /> <span>Home</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/minha-performance`}
            className="mt-4"
            activeClassName="active"
          >
            <RiFootballFill size={24} /> <span>Minha performance</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/assinaturas`}
            className="mt-4"
            activeClassName="active"
          >
            <TiStarOutline size={24} /> <span>Assinaturas</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/novidades`}
            className="mt-4"
            activeClassName="active"
          >
            <RiNewspaperLine size={24} /> <span>Novidades</span>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/perfil`}
            className="mt-4"
            activeClassName="active"
          >
            <RiUser3Line size={24} /> <span>Perfil</span>
          </NavLink>
          <button
            type="button"
            className="border-0 bg-transparent mt-5 mt-lg-4 text-error"
            onClick={signOut}
          >
            <RiLogoutCircleRLine size={24} /> <span>Sair</span>
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Menu;
