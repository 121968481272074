/* eslint-disable import/no-duplicates */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ImCross } from 'react-icons/im';
import { BsPlus } from 'react-icons/bs';
import { GoKebabVertical } from 'react-icons/go';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import axios, { CancelTokenSource } from 'axios';
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  lastDayOfMonth,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subHours,
  subMilliseconds,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import getValidationsErrors from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import { Container, Score, Modal } from './styles';
import FloatProfile from '~/components/FloatProfile';
import Table, { IColumn } from '~/components/Table';
import BoxPlan from '~/components/BoxPlan';
import Input from '~/components/Input';
import Select, { IOption as ITeam } from '~/components/Select';
import InputMask from '~/components/InputMask';

interface ITeamResponse {
  id: string;
  name: string;
}

interface ITeamSelected {
  id?: string;
  value: string;
  selected?: boolean;
  notSelectable?: boolean;
}

interface IFormData {
  score_home_team: string;
  score_away_team: string;
  date: string;
}
interface IGameResponse {
  id: string;
  home_team: ITeamResponse;
  home_score: number;
  score: string;
  away_team: ITeamResponse;
  away_score: number;
  date_match: string;
}

interface IResponse {
  data: IGameResponse[];
  from: number;
  to: number;
  total: number;
}

interface IGame {
  id: string;
  home_team: string;
  score: string;
  away_team: string;
  date: string;
}

let cancelTokenTeams: CancelTokenSource;

const Home: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [games, setGames] = useState<IGame[]>([]);
  const [show, setShow] = useState(false);
  const [homeTeams, setHomeTeams] = useState<ITeam[]>([]);
  const [homeTeamSelected, setHomeTeamSelected] = useState({} as ITeamSelected);
  const [awayTeams, setAwayTeams] = useState<ITeam[]>([]);
  const [awayTeamSelected, setAwayTeamSelected] = useState({} as ITeamSelected);
  const [errDate, setErrDate] = useState('');
  const [period, setPeriod] = useState({
    initialDate: subHours(startOfWeek(new Date()), 3),
    endDate: subHours(endOfWeek(new Date()), 3),
  });
  const [optionSelected, setOptionSelected] = useState('week');
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState({
    from: 0,
    to: 0,
    total: 0,
  });

  const loadTeams = useCallback(async (search?: string) => {
    cancelTokenTeams = axios.CancelToken.source();
    const response = await api.get<ITeamResponse[]>('teams', {
      params: {
        search,
      },
      cancelToken: cancelTokenTeams.token,
    });
    return response;
  }, []);

  useEffect(() => {
    loadTeams().then((response) => {
      const data = response.data.map<ITeam>((team) => ({
        id: team.id,
        value: team.name,
        selected: false,
      }));
      setHomeTeams(data);
      setAwayTeams(data);
    });
  }, [loadTeams]);

  useEffect(() => {
    api
      .get<IResponse>('games', {
        params: {
          initialDate: period.initialDate,
          endDate: period.endDate,
          page,
        },
      })
      .then((response) => {
        const data = response.data.data.map<IGame>((game) => ({
          id: game.id,
          home_team: game.home_team.name,
          away_team: game.away_team.name,
          score: `${game.home_score} - ${game.away_score}`,
          date: format(parseISO(game.date_match), "dd 'de' MMMM 'de' yyyy", {
            locale: ptBR,
          }),
        }));
        setGames(data);
        setTableData({
          from: response.data.from,
          to: response.data.to,
          total: response.data.total,
        });
      });
  }, [page, period.endDate, period.initialDate, user]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: 'Time da casa',
        selector: 'home_team',
      },
      {
        name: 'Placar',
        selector: 'score',
        cell: (row) => (
          <Score>
            <p>{row.score}</p>
          </Score>
        ),
      },
      {
        name: 'Time de Fora',
        selector: 'away_team',
      },
      {
        name: 'Data',
        selector: 'date',
      },
      {
        name: '',
        cell: () => (
          <button type="button" className="border-0 bg-transparent">
            <GoKebabVertical size={24} color="#B8B9BA" />
          </button>
        ),
      },
    ],
    []
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setHomeTeamSelected({} as ITeamSelected);
    setAwayTeamSelected({} as ITeamSelected);
    setErrDate('');
  }, []);

  const handleClickNewGame = useCallback(() => {
    setShow(true);
  }, []);

  const handleChangeTextHomeTeam = useCallback(
    async (value) => {
      if (cancelTokenTeams) {
        cancelTokenTeams.cancel('canceled');
      }

      try {
        const response = await loadTeams(value);

        const data = response.data.map<ITeam>((team) => ({
          id: team.id,
          value: team.name,
          selected: false,
        }));

        if (data.length > 0) {
          setHomeTeams(data);
        } else {
          const newTeam: ITeamSelected = {
            value,
            selected: true,
          };
          setHomeTeamSelected(newTeam);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log(error);
        }
      }
    },
    [loadTeams]
  );

  const handleChangeHomeTeam = useCallback(
    async (team) => {
      const newHomeTeams = homeTeams.map((teamData) => {
        if (teamData.id === team.id) {
          return { ...teamData, selected: true };
        }
        return { ...teamData, selected: false };
      });
      setHomeTeamSelected(team);
      setHomeTeams(newHomeTeams);
    },
    [homeTeams]
  );

  const handleChangeTextAwayTeam = useCallback(
    async (value) => {
      if (cancelTokenTeams) {
        cancelTokenTeams.cancel('canceled');
      }

      try {
        const response = await loadTeams(value);

        const data = response.data.map<ITeam>((team) => ({
          id: team.id,
          value: team.name,
          selected: false,
        }));

        if (data.length > 0) {
          setAwayTeams(data);
        } else {
          const newTeam: ITeamSelected = {
            value,
            selected: true,
          };
          setAwayTeamSelected(newTeam);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log(error);
        }
      }
    },
    [loadTeams]
  );

  const handleChangeAwayTeam = useCallback(
    async (team) => {
      const newHomeTeams = homeTeams.map((teamData) => {
        if (teamData.id === team.id) {
          return { ...teamData, selected: true };
        }
        return { ...teamData, selected: false };
      });
      setAwayTeamSelected(team);
      setAwayTeams(newHomeTeams);
    },
    [homeTeams]
  );

  const handleChangeDate = useCallback((e) => {
    setErrDate('');
    const [day, month, year] = e.target.value.split('/');
    const americanDate = new Date(year, month - 1, day);
    if (americanDate > new Date()) {
      setErrDate('Data da partida inválida');
    }

    if (day > lastDayOfMonth(new Date(year, month - 1, 1)).getDate()) {
      setErrDate('Data da partida inválida');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          home_team: Yup.string().required('O time da casa é obrigatório'),
          score_home_team: Yup.string().required('O placar é obrigatório'),
          away_team: Yup.string().required('O time de fora é obrigatório'),
          score_away_team: Yup.string().required('O placar é obrigatório'),
          date: Yup.string().required('Data da partida inválida'),
        });

        await schema.validate(
          {
            ...data,
            home_team:
              Object.keys(homeTeamSelected).length > 0
                ? homeTeamSelected.value
                : undefined,
            away_team:
              Object.keys(awayTeamSelected).length > 0
                ? awayTeamSelected.value
                : undefined,
            date: errDate ? undefined : data.date,
          },
          {
            abortEarly: false,
          }
        );

        let homeTeamId = homeTeamSelected.id;
        if (!homeTeamId) {
          const response = await api.post('teams', {
            name: homeTeamSelected.value,
          });

          setHomeTeams((state) => [
            ...state,
            {
              id: response.data.id,
              value: response.data.name,
              selected: false,
            },
          ]);
          setAwayTeams((state) => [
            ...state,
            {
              id: response.data.id,
              value: response.data.name,
              selected: false,
            },
          ]);

          homeTeamId = response.data.id;
        }

        let awayTeamId = awayTeamSelected.id;
        if (!awayTeamId) {
          const response = await api.post('teams', {
            name: awayTeamSelected.value,
          });

          setHomeTeams((state) => [
            ...state,
            {
              id: response.data.id,
              value: response.data.name,
              selected: false,
            },
          ]);
          setAwayTeams((state) => [
            ...state,
            {
              id: response.data.id,
              value: response.data.name,
              selected: false,
            },
          ]);

          awayTeamId = response.data.id;
        }

        const [day, month, year] = data.date.split('/');

        const formData = {
          home_team_id: homeTeamId,
          away_team_id: awayTeamId,
          home_score: data.score_home_team,
          away_score: data.score_away_team,
          date_match: new Date(
            parseInt(year, 10),
            parseInt(month, 10) - 1,
            parseInt(day, 10)
          ),
          created_user_id: user.id,
          updated_user_id: user.id,
        };

        const response = await api.post('games', formData);

        await api.post('users-games', {
          user_id: user.id,
          game_id: response.data.id,
          date_match: formData.date_match,
        });

        if (
          formData.date_match >= period.initialDate &&
          formData.date_match <= period.endDate
        ) {
          const newGame: IGame = {
            id: response.data.id,
            home_team: homeTeamSelected.value,
            score: `${formData.home_score} - ${formData.away_score}`,
            away_team: awayTeamSelected.value,
            date: format(formData.date_match, "dd 'de' MMMM 'de' yyyy", {
              locale: ptBR,
            }),
          };

          setGames((state) => [newGame, ...state]);
        }
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          formRef.current?.setErrors(errors);
          console.log(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      }
    },
    [
      homeTeamSelected,
      awayTeamSelected,
      errDate,
      user.id,
      period.initialDate,
      period.endDate,
      handleClose,
    ]
  );

  const handleSelectOption = useCallback((option) => {
    switch (option) {
      case 'month':
        setPeriod({
          initialDate: subHours(startOfMonth(new Date()), 3),
          endDate: subHours(endOfMonth(new Date()), 3),
        });
        break;
      case 'year':
        setPeriod({
          initialDate: subHours(startOfYear(new Date()), 3),
          endDate: subHours(endOfYear(new Date()), 3),
        });
        break;
      default:
        setPeriod({
          initialDate: subHours(startOfWeek(new Date()), 3),
          endDate: subHours(endOfWeek(new Date()), 3),
        });
        break;
    }
    setPage(1);
    setOptionSelected(option);
  }, []);

  const handleChagePage = useCallback((pageNumber) => {
    setPage(pageNumber);
  }, []);

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-0 mb-4 mb-lg-0">
            <h2 className="fw-semibold">Olá, {user.name}! ⚽</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
          <div className="col-12 pe-lg-5 mt-lg-3 mb-5 mb-lg-4 order-0 order-lg-1">
            <BoxPlan className="p-4" />
          </div>
          <div className="col-lg-7 order-1 mb-3 mb-lg-0">
            <div className="model-box w-100 h-100 px-5 py-4 d-flex flex-column justify-content-center">
              <div className="w-100 w-lg-75">
                <div className="w-100 w-lg-50">
                  <h5 className="text-white fw-bold">Modelo</h5>
                  <p className="text-white">
                    Chuteira de Campo Adidas Artilheira V
                  </p>
                </div>
                <div className="d-flex">
                  <div>
                    <h5 className="text-white fw-bold">Tempo</h5>
                    <p className="text-white mb-0">Há 2 Meses</p>
                  </div>
                  <div className="ms-5 brand">
                    <h5 className="text-white fw-bold">Marca</h5>
                    <p className="text-white mb-0">Adidas</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 pe-lg-5 order-1">
            <div className="box px-4 px-xl-5 py-4">
              <h6 className="fw-bold mb-3">Informações</h6>
              <p className="small mb-1">Cartões</p>
              <div className="d-flex mb-4">
                <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                  <div className="cards yellow d-flex align-items-center justify-content-center me-2">
                    <div />
                  </div>
                  <p className="mb-0">
                    {user.statistic.yellow_card.toString().padStart(2, '0')}
                  </p>
                </div>
                <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                  <div className="cards red d-flex align-items-center justify-content-center me-2">
                    <div />
                  </div>
                  <p className="mb-0">
                    {user.statistic.red_card.toString().padStart(2, '0')}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="w-100 w-lg-auto">
                  <p className="small mb-1">Gols</p>
                  <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center">
                    <p className="mb-0">
                      {user.statistic.goals.toString().padStart(2, '0')}
                    </p>
                  </div>
                </div>
                <div className="mx-3 mx-lg-4 w-100 w-lg-auto">
                  <p className="small mb-1">Faltas</p>
                  <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center">
                    <p className="mb-0">
                      {user.statistic.fouls.toString().padStart(2, '0')}
                    </p>
                  </div>
                </div>
                <div className="w-100 w-lg-auto">
                  <p className="small mb-1">Pênaltis</p>
                  <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center">
                    <p className="mb-0">
                      {user.statistic.penalties.toString().padStart(2, '0')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 pe-lg-5">
            <div className="pb-3 my-games">
              <div className="d-flex justify-content-between align-items-center align-items-lg-end">
                <h2 className="mb-5 order-0">Meus jogos</h2>
                <button
                  type="button"
                  className="btn btn-new rounded-pill px-lg-5 fw-medium d-flex align-items-center justify-content-center mb-5 mb-lg-0"
                  onClick={handleClickNewGame}
                >
                  <BsPlus size={24} color="#fff" className="me-2" />
                  <span>Novo Jogo</span>
                </button>
              </div>
              <div className="order-1 order-lg-0 w-100 w-lg-auto d-flex align-items-center justify-content-center justify-content-lg-start">
                <button
                  type="button"
                  className={`border-0 bg-transparent pb-1 px-3 fw-medium ${
                    optionSelected === 'week' ? 'active' : ''
                  }`}
                  onClick={() => handleSelectOption('week')}
                >
                  Esta Semana
                </button>
                <button
                  type="button"
                  className={`border-0 bg-transparent pb-1 px-3 fw-medium ${
                    optionSelected === 'month' ? 'active' : ''
                  }`}
                  onClick={() => handleSelectOption('month')}
                >
                  Este Mês
                </button>
                <button
                  type="button"
                  className={`border-0 bg-transparent pb-1 px-3 fw-medium ${
                    optionSelected === 'year' ? 'active' : ''
                  }`}
                  onClick={() => handleSelectOption('year')}
                >
                  Este Ano
                </button>
              </div>
            </div>
            <div className="table-content w-100 pt-2">
              <Table
                columns={columns}
                data={games}
                pagination
                fromData={tableData.from}
                toData={tableData.to}
                totalData={tableData.total}
                itemsPerPage={5}
                selectedPage={page}
                onChangePage={handleChagePage}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit} className="col-12">
          <Modal.Header className="border-0" closeButton>
            <h3 className="h2">Novo Jogo</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-5 d-flex flex-column">
                <label className="mb-3">
                  <span className="d-block mb-1">Time da casa</span>
                  <Select
                    name="home_team"
                    options={homeTeams}
                    onChangeText={handleChangeTextHomeTeam}
                    onChange={handleChangeHomeTeam}
                  />
                </label>

                <label className="mb-3">
                  <span className="d-block mb-1">Placar</span>
                  <Input type="number" name="score_home_team" />
                </label>
              </div>
              <div className="col-lg-2 d-flex align-items-center justify-content-center">
                <ImCross size={100} color="#202020" />
              </div>
              <div className="col-lg-5 d-flex flex-column">
                <label className="mb-3">
                  <span className="d-block mb-1">Time de fora</span>
                  <Select
                    name="away_team"
                    options={awayTeams}
                    onChangeText={handleChangeTextAwayTeam}
                    onChange={handleChangeAwayTeam}
                  />
                </label>
                <label className="mb-3">
                  <span className="d-block mb-1">Placar</span>
                  <Input type="number" name="score_away_team" />
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 d-flex justify-content-between align-items-end">
            <label>
              <span className="d-block mb-1">Data da partida</span>
              <InputMask
                kind="datetime"
                name="date"
                options={{
                  format: 'DD/MM/YYYY',
                }}
                placeholder="01/01/2023"
                onChange={handleChangeDate}
                error={errDate}
              />
            </label>
            <button
              type="submit"
              className="border-0 fw-medium px-3 py-2 text-white btn-save"
            >
              <span>Salvar</span>
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Home;
