import { darken } from 'polished';
import styled from 'styled-components';

import field from '~/assets/defaults/field.svg';
import verticalField from '~/assets/defaults/vertical-field.svg';

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  .btn-download-all {
    background: #b6b7b6;
    width: 96px;
  }

  .box {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;

    .field {
      background-image: url(${verticalField});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      width: 100%;

      hr {
        border-color: #4cb188;
        opacity: 1;
      }

      button {
        background: #005c37;
        width: 96px;
      }
    }

    .box {
      border: none;
    }

    h6 {
      color: #202020;
    }

    p {
      color: #202020;
    }

    p.subtitle {
      color: #aaaaaa;
    }

    button {
      span.icon {
        width: 30px;
        height: 30px;
        background: #b6b7b6;
        border-radius: 50%;
      }
    }

    .data {
      .col-6 {
        width: 100%;

        span {
          border: 2px solid #00ab64;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          color: #00ab64;
        }
      }
    }

    hr {
      border-color: #e6ece9;
      opacity: 1;
    }

    .sub-box {
      background: #f9f9f9;
      border-radius: 6px;

      .cards {
        width: 20px;
        height: 20px;

        div {
          width: 11px;
          height: 16px;
          border-radius: 1px;
          position: relative;

          :before {
            content: '';
            width: 11px;
            height: 16px;
            border-radius: 1px;
            background-color: #2d2e2e;
            position: absolute;
            top: -2px;
            right: -4px;
            display: block;
          }

          :after {
            content: '';
            width: 11px;
            height: 16px;
            border-radius: 1px;
            background-color: #202020;
            position: absolute;
            top: 0px;
            right: 0px;
            display: block;
          }
        }
      }

      .cards.yellow div {
        :before {
          background-color: #ffca18;
        }

        :after {
          background-color: #e5b100;
        }
      }

      .cards.red div {
        :before {
          background-color: #e14640;
        }

        :after {
          background-color: #ce130c;
        }
      }

      + .sub-box {
        margin-left: 5px;
      }
    }
  }

  .my-games {
    border-bottom: 4px solid #efefef;

    > div {
      margin-bottom: -20px;

      button {
        transition-duration: 0.3s;
        color: #a4a4a4;
        border-bottom: 4px solid transparent !important;
      }

      button:hover,
      button.active {
        color: #100f0f;
        border-color: #0d0d0d !important;
      }
    }

    .btn-new {
      border-color: #00ab64 !important;
      background-color: #00ab64 !important;
      color: #ffffff !important;

      :hover {
        border-color: ${darken(0.05, '#00AB64')} !important;
        background-color: ${darken(0.05, '#00AB64')} !important;
      }
    }
  }

  @media screen and (min-width: 390px) {
    .box {
      .data {
        .col-6 {
          width: 50%;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .box {
      .field {
        background-image: url(${field});
        border-radius: 10px;
        width: 250px;
        height: 500px;
      }

      .box {
        border: 1px solid #d9d9d9;
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  height: 118px;
  width: 118px;
  border-radius: 50%;
`;

export const Score = styled.div`
  background: rgba(66, 169, 76, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;

  p {
    color: #42a959;
    font-weight: 600;
    margin: 0;
  }
`;
