import styled from 'styled-components';

import background from '~/assets/banners/background-sign-up.png';

export const Container = styled.div`
  background-color: #202020;
  min-height: 100vh;

  h1 {
    color: #ffffff;
  }

  h4 {
    color: #e4e4e4;
  }

  h1,
  h4 {
    line-height: 168.5%;
    margin: 0;
  }

  label {
    color: #e4e4e4;
    line-height: 19px;

    .input {
      background: #3c3c3c;
      border: 1px solid #626262 !important;
      margin-bottom: 5px;

      input {
        color: #ffffff;
        line-height: 140%;

        ::placeholder {
          color: #b2b0b0;
        }
      }
    }

    .checkbox {
      width: 24px;
      height: 24px;
      background: #ffffff;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.3s;

      svg {
        position: relative;
      }
    }

    .checkbox.checked {
      background-color: #00a87f;
    }

    .terms {
      width: calc(100% - 40px);
    }
  }

  .forgot-password {
    color: #e4e4e4;
    letter-spacing: -0.3px;
  }

  .btn-submit {
    height: 44px;
    background: linear-gradient(90deg, #0fdfb7 -44.4%, #aaf23c 130.97%);
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  p {
    color: #e4e4e4;
    line-height: 168.5%;
    letter-spacing: -0.3px;

    a {
      color: #1fc19a;
    }
  }
`;

export const Background = styled.div`
  position: absolute;
  height: 100%;
  background-image: url(${background});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
`;
