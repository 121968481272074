import styled, { css } from 'styled-components';

interface IStatus {
  status: string;
}

export const Container = styled.div`
  background: #f9f9f9;
  border-radius: 14px;

  div {
    div.infos {
      svg,
      p {
        margin-bottom: 10px !important;
      }

      p {
        color: #4a7d5c;
        width: calc(100% - 35px);
      }

      span.plan-name {
        background: #aef7ac;
        border-radius: 18px;
        color: #2d2e2e;
      }
    }

    a {
      span {
        text-decoration-line: underline;
        color: #202020;
        margin-bottom: 0;
      }

      svg {
        margin-bottom: 0 !important;
      }
    }
  }

  @media screen and (min-width: 340px) {
    div {
      div.infos {
        svg,
        p,
        span.plan-name {
          margin-bottom: 10px !important;
        }

        p {
          width: calc(100% - 123px);
        }
      }
    }
  }

  @media screen and (min-width: 425px) {
    div {
      div.infos {
        p {
          width: unset;
        }

        span.plan-name {
          margin-left: 10px;
        }
      }
    }
  }

  @media screen and (min-width: 520px) {
    div {
      div.infos {
        svg,
        p,
        span.plan-name {
          margin-bottom: 0px !important;
        }
      }
    }
  }
`;

export const Status = styled.div<IStatus>`
  p {
    width: calc(100% - 25px);
  }

  ${(props) =>
    props.status === 'paid' &&
    css`
      background: #defff1;
      p {
        color: #00ab64 !important;
      }
    `}

  ${(props) =>
    props.status === 'Awaiting Payment' &&
    css`
      background: #fff1dd;
      p {
        color: #927221 !important;
      }
    `}

  ${(props) =>
    props.status === 'Overdue' &&
    css`
      background: #ffdfde;
      p {
        color: #e14640 !important;
      }
    `}
`;
