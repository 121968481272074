import React from 'react';
import { useAuth } from '~/hooks/Auth';

import { Container, Avatar } from './styles';

import avatar from '~/assets/defaults/default-avatar.svg';

const FloatProfile: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container className="d-flex">
      <div className="order-1 order-lg-0">
        <p className="mb-0 username fw-semibold text-lg-end">{user.name}</p>
        <p className="mb-0 text-lg-end area">
          {user.statistic.performance_area}
        </p>
      </div>
      <Avatar
        src={user.avatar ? user.avatar.avatar_url : avatar}
        className="me-3 me-lg-0 ms-0 ms-lg-3 order-0 order-lg-1"
      />
    </Container>
  );
};

export default FloatProfile;
