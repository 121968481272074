/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { FiAlertCircle, FiCheckCircle, FiXCircle } from 'react-icons/fi';

import { Container, Status } from './styles';
import FloatProfile from '~/components/FloatProfile';
import BoxPlan from '~/components/BoxPlan';
import api from '~/services/api';
import { differenceInDays, format, parseISO } from 'date-fns';
import { formatPrice } from '~/utils/format';
import { ptBR } from 'date-fns/locale';

interface IOrderResponse {
  id: string;
  price: number;
  status: string;
  due_date: string;
  updated_at: string;
  subscription: {
    plan: {
      name: string;
    };
  };
}

interface IOrder {
  id: string;
  price: string;
  status: string;
  due_date: string;
  updated_at: string;
  plan: string;
  monthReference: string;
  info: string;
}

const Subscriptions: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);

  useEffect(() => {
    api.get<IOrderResponse[]>('orders').then((response) => {
      const data = response.data.map<IOrder>((order) => {
        const currentDate = new Date();
        const days = differenceInDays(currentDate, parseISO(order.due_date));
        let info = `Sua assinatura irá vencer em ${days} ${
          days > 1 ? 'dias' : 'dia'
        }`;
        if (order.status !== 'paid' && parseISO(order.due_date) < currentDate) {
          info = `Seu pagamento está em atraso`;
        } else {
          info = `Pagamento realizado dia ${format(
            parseISO(order.updated_at),
            'dd/MM/yyyy'
          )}`;
        }
        const monthReference = format(parseISO(order.due_date), 'MMMM', {
          locale: ptBR,
        });
        return {
          ...order,
          price: formatPrice(order.price),
          due_date: format(parseISO(order.due_date), 'dd/MM/yyyy'),
          plan: order.subscription.plan.name,
          monthReference: `${monthReference[0].toUpperCase()}${monthReference.slice(
            1
          )}`,
          info,
        };
      });
      setOrders(data);
    });
  }, []);

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 mt-4 mt-lg-0">
            <h2 className="fw-semibold">Assinaturas</h2>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
          <div className="col-12 pe-lg-5 mt-3 mb-4">
            <BoxPlan className="p-4" />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 pe-lg-5">
            <div className="d-flex justify-content-between align-items-center pb-3 my-games">
              <div>
                <h2 className="mb-4 h5 fw-semibold">Minha assinatura</h2>
              </div>
            </div>
            {orders.map((order) => (
              <div
                key={order.id}
                className="box w-100 mt-4 px-3 px-lg-5 py-4 mb-3"
              >
                <div className="d-flex flex-wrap justify-content-between align-items-center mb-5">
                  <div className="order-1 order-lg-0">
                    <p className="mb-1">Valor:</p>
                    <p className="h2 mb-0 fw-medium">R$ {order.price}</p>
                  </div>
                  <Status
                    status={order.status}
                    className="sub-box d-flex align-items-center px-3 py-1 rounded-pill warning order-0 order-lg-1 mb-3 mb-lg-0"
                  >
                    {order.status === 'paid' && (
                      <FiCheckCircle
                        size={16}
                        color="#00ab64"
                        className="me-3"
                      />
                    )}
                    {order.status === 'Awaiting Payment' && (
                      <FiAlertCircle
                        size={16}
                        color="#927221"
                        className="me-3"
                      />
                    )}
                    {order.status === 'Overdue' && (
                      <FiXCircle size={16} color="#e14640" className="me-3" />
                    )}
                    <p className="mb-0">{order.info}</p>
                  </Status>
                </div>
                <div className="d-flex flex-wrap align-items-end justify-content-between">
                  <div>
                    <p className="d-flex mb-1">
                      Vencimento:{' '}
                      <span className="fw-semibold ms-2">{order.due_date}</span>
                    </p>
                    <p className="d-flex mb-1">
                      Plano:{' '}
                      <span className="fw-semibold ms-2">{order.plan}</span>
                    </p>
                    <p className="d-flex mb-1">
                      Mês de referência:{' '}
                      <span className="fw-semibold ms-2">
                        {order.monthReference}
                      </span>
                    </p>
                  </div>
                  {order.status !== 'paid' && (
                    <button
                      type="button"
                      className="btn btn-pay rounded-pill fw-semibold px-5 py-1 mt-3 mt-lg-0"
                    >
                      Pagar
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Subscriptions;
