/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useEffect, useState } from 'react';
import JSPDF from 'jspdf';

import { Container, Avatar } from './styles';
import FloatProfile from '~/components/FloatProfile';
import { RiEdit2Fill } from 'react-icons/ri';
import { HiDownload } from 'react-icons/hi';
import api from '~/services/api';
import { format, parseISO } from 'date-fns';
import { useAuth } from '~/hooks/Auth';

import avatar from '~/assets/defaults/default-avatar.svg';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

interface IStatistic {
  id: string;
  user_id: string;
  team_id: string;
  from: string;
  to: string;
  currently: boolean;
  performance_area: string;
  passes: number;
  goal_shooting: number;
  corner: number;
  shot: number;
  impediment: number;
  ball_possession: number;
  yellow_card: number;
  red_card: number;
  goals: number;
  fouls: number;
  penalties: number;
  team: {
    name: string;
  };
}

const MyPerformance: React.FC = () => {
  const { user } = useAuth();
  const [statistics, setStatistics] = useState<IStatistic[]>([]);

  useEffect(() => {
    api.get<IStatistic[]>('statistics/users').then((response) => {
      const data = response.data.map((statistic) => ({
        ...statistic,
        from: format(parseISO(statistic.from), 'dd/MM/yyyy'),
        to: statistic.to
          ? format(parseISO(statistic.to), 'dd/MM/yyyy')
          : 'Atualmente',
      }));
      setStatistics(data);
    });
  }, []);

  const handleClickDownloadAll = useCallback(async () => {
    const elements = document.getElementsByClassName('statistic') as any;
    if (elements.length > 0) {
      const pdf = new JSPDF({
        orientation: 'landscape',
        unit: 'px',
      });

      let index = 1;
      for (const element of elements as HTMLElement[]) {
        const btnDownloadElement = element.querySelector(
          `.btn-download`
        ) as HTMLButtonElement;
        const btnEditElement = element.querySelector(
          `.btn-edit`
        ) as HTMLButtonElement;
        if (btnDownloadElement) btnDownloadElement.style.opacity = '0';
        if (btnEditElement) btnEditElement.style.opacity = '0';

        const dataUrl = await toPng(element, {
          width: element.offsetWidth,
          height: element.offsetHeight,
        });

        if (btnDownloadElement) btnDownloadElement.style.opacity = '1';
        if (btnEditElement) btnEditElement.style.opacity = '1';
        pdf.addImage(dataUrl, 15, 15, 600, 285);

        if (index !== elements.length) {
          pdf.addPage();
        }

        index += 1;
      }

      pdf.save('minha-performance.pdf');
    }
  }, []);

  const handleClickDownload = useCallback(
    async (divId: string, statistic: IStatistic) => {
      const element = document.getElementById(divId);
      if (element) {
        const btnDownloadElement = element.querySelector(
          `.btn-download`
        ) as HTMLButtonElement;
        const btnEditElement = element.querySelector(
          `.btn-edit`
        ) as HTMLButtonElement;
        if (btnDownloadElement) btnDownloadElement.style.opacity = '0';
        if (btnEditElement) btnEditElement.style.opacity = '0';

        const dataUrl = await toPng(element, {
          width: element.offsetWidth,
          height: element.offsetHeight,
        });

        if (btnDownloadElement) btnDownloadElement.style.opacity = '1';
        if (btnEditElement) btnEditElement.style.opacity = '1';

        const team = statistic.team.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');

        const initialDate = statistic.from.replaceAll('/', '-');
        const endDate = statistic.currently
          ? 'atualmente'
          : statistic.to.replaceAll('/', '-');

        const fileName = `minha-performance-${team}-desde-${initialDate}-ate-${endDate}.pdf`;

        const pdf = new JSPDF({
          orientation: 'landscape',
          unit: 'px',
        });
        pdf.addImage(dataUrl, 15, 15, 600, 285);
        pdf.save(fileName);
      }
    },
    []
  );

  return (
    <Container className="py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 mt-4 mt-lg-0 d-flex align-items-center">
            <h2 className="fw-semibold me-3 mb-0">Minha Performance</h2>
            <button
              type="button"
              className="btn-download-all border-0 rounded-pill fw-medium px-2 py-1 py-lg-2 px-xl-4 small text-white w-lg-100 w-xl-auto"
              onClick={handleClickDownloadAll}
            >
              <HiDownload size={18} color="#fff" className="me-1" />
              <span>Baixar tudo</span>
            </button>
          </div>
          <div className="col-lg-6 d-none d-lg-flex justify-content-end pe-lg-5">
            <FloatProfile />
          </div>
          <div className="col-12 mt-4 pe-lg-5">
            {statistics.map((statistic) => (
              <div
                key={statistic.id}
                id={`statistic-${statistic.id.slice(0, 6)}`}
                className="statistic box px-3 px-lg-4 px-xl-5 py-5 mb-4"
              >
                <div className="row">
                  <div className="col-lg-4 mb-3 mb-lg-0">
                    <div className="field w-lg-100 w-xl-auto p-4 px-lg-3 px-xl-4 d-flex flex-column">
                      <div>
                        <Avatar
                          src={user.avatar ? user.avatar.avatar_url : avatar}
                          className="mx-auto mt-n5 mb-4"
                        />
                        <h2 className="h4 text-center text-white fw-semibold mb-xl-4 px-xl-4">
                          {statistic.performance_area}
                        </h2>
                      </div>
                      <div className="d-flex flex-lg-column justify-content-between">
                        <div>
                          <p className="mb-0 text-white fw-light">Time:</p>
                          <p className="text-white fw-medium">
                            {statistic.team.name}
                          </p>
                        </div>
                        <hr className="d-none d-lg-block" />
                        <div className="d-flex">
                          <div className="me-4">
                            <p className="mb-0 text-white fw-light">Desde:</p>
                            <p className="text-white fw-medium">
                              {statistic.from}
                            </p>
                          </div>
                          <div>
                            <p className="mb-0 text-white fw-light">Até:</p>
                            <p className="text-white fw-medium">
                              {statistic.to}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-lg-column flex-xl-row mt-auto justify-content-between">
                        <button
                          type="button"
                          className="btn-download border-0 rounded-pill fw-medium px-2 py-1 py-lg-2 px-xl-4 small text-white w-lg-100 w-xl-auto"
                          onClick={() =>
                            handleClickDownload(
                              `statistic-${statistic.id.slice(0, 6)}`,
                              statistic
                            )
                          }
                        >
                          <HiDownload size={18} color="#fff" className="me-1" />
                          <span>Baixar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="box px-2 py-3 p-lg-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h6 className="fw-bold">Estatísticas</h6>
                          <p className="fw-medium subtitle">Meus dados</p>
                        </div>
                        <button
                          type="button"
                          className="btn-edit border-0 bg-transparent d-flex align-items-center justify-content-center"
                        >
                          <span className="icon d-flex align-items-center justify-content-center me-3">
                            <RiEdit2Fill size={18} color="#fff" />
                          </span>
                          <span className="fw-medium">Editar</span>
                        </button>
                      </div>
                      <div className="row my-4 data">
                        <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                          <p className="fw-medium mb-0">Passes</p>
                          <span className="d-flex align-items-center justify-content-center fw-bold">
                            {statistic.passes.toString().padStart(2, '0')}
                          </span>
                        </div>
                        <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                          <p className="fw-medium mb-0">Tiro</p>
                          <span className="d-flex align-items-center justify-content-center fw-bold">
                            {statistic.shot.toString().padStart(2, '0')}
                          </span>
                        </div>
                        <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                          <p className="fw-medium mb-0">Tiro de meta</p>
                          <span className="d-flex align-items-center justify-content-center fw-bold">
                            {statistic.goal_shooting
                              .toString()
                              .padStart(2, '0')}
                          </span>
                        </div>
                        <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                          <p className="fw-medium mb-0">Impedimento</p>
                          <span className="d-flex align-items-center justify-content-center fw-bold">
                            {statistic.impediment.toString().padStart(2, '0')}
                          </span>
                        </div>
                        <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                          <p className="fw-medium mb-0">Escanteio</p>
                          <span className="d-flex align-items-center justify-content-center fw-bold">
                            {statistic.corner.toString().padStart(2, '0')}
                          </span>
                        </div>
                        <div className="col-6 d-flex justify-content-between align-items-center mt-3 my-lg-3">
                          <p className="fw-medium mb-0">Posse de bola</p>
                          <span className="d-flex align-items-center justify-content-center fw-bold">
                            {statistic.ball_possession
                              .toString()
                              .padStart(2, '0')}
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="row pt-3 mt-n1">
                        <div className="col-lg-4">
                          <p className="small mb-1">Cartões</p>
                          <div className="d-flex mb-4">
                            <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-50 w-xl-auto">
                              <div className="cards yellow d-flex align-items-center justify-content-center me-2">
                                <div />
                              </div>
                              <p className="mb-0">
                                {statistic.yellow_card
                                  .toString()
                                  .padStart(2, '0')}
                              </p>
                            </div>
                            <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-50 w-xl-auto">
                              <div className="cards red d-flex align-items-center justify-content-center me-2">
                                <div />
                              </div>
                              <p className="mb-0">
                                {statistic.red_card.toString().padStart(2, '0')}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 d-flex">
                          <div className="w-100 w-lg-auto">
                            <p className="small mb-1">Gols</p>
                            <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                              <p className="mb-0">
                                {statistic.goals.toString().padStart(2, '0')}
                              </p>
                            </div>
                          </div>
                          <div className="mx-3 mx-lg-4 w-100 w-lg-auto">
                            <p className="small mb-1">Faltas</p>
                            <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                              <p className="mb-0">
                                {statistic.fouls.toString().padStart(2, '0')}
                              </p>
                            </div>
                          </div>
                          <div className="w-100 w-lg-auto">
                            <p className="small mb-1">Pênaltis</p>
                            <div className="sub-box py-2 px-3 d-flex align-items-center justify-content-center w-100 w-lg-auto">
                              <p className="mb-0">
                                {statistic.penalties
                                  .toString()
                                  .padStart(2, '0')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MyPerformance;
